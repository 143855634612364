<template>
	<div class="calendar-events">
		<b-container class="mt-5">
			<b-row class="mb-4" align-v="center">
				<b-col cols="12">
					<h1 class="display-4  super-strong">Vinculación : Eventos</h1>
					<hr class="line" />
				</b-col>
			</b-row>

			<b-row class="mb-5">
				<b-col md="12">
					<table>
						<thead class="blue-grey darken-3">
							<tr class="text-uppercase">
								<td class="white-text text-center">&nbsp; Fecha/Hora &nbsp;</td>
								<td class="white-text text-center">Evento</td>
								<td class="white-text text-center">Organizador</td>
								<td class="white-text text-center">Sede</td>
								<td class="white-text text-center">Cartel</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, i) in events" :key="i">
								<td>
									<b-button v-if="item.eventDate" pill size="sm">
										<i class="fa-solid fa-calendar-days"></i>
										{{ item.eventDate }} {{ item.eventSchedule }}
									</b-button>
								</td>
								<td>
									<a :href="`/vinculacion/evento/${item.uuid}`" class="strong">
										{{ item.eventName }}
									</a>
								</td>
								<td>
									{{ item.fullName }}<br />
									<small class="blue-grey-text text-lighten-1">
										{{
											item.mexicanCommunity ? item.mexicanCommunity : item.rme
										}}
									</small>
								</td>
								<td>
									{{ item.location }}
									<a :href="item.location"></a>
								</td>
								<td>
									<div
										v-for="(picture, i) in item.eventPoster"
										:key="i"
										class="text-center"
									>
										<b-button
											size="sm"
											target="_blank"
											:href="picture.content"
											v-if="picture.type === 'application/pdf'"
										>
											Descargar
										</b-button>
										<a v-else :href="`/vinculacion/evento/${item.uuid}`">
											<b-img
												:src="picture.content"
												rounded="circle"
												:alt="item.eventName"
												width="70"
												height="70"
												class="shadow-2"
											></b-img>
										</a>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>

			<b-row>
				<b-col col>
					<PaginationComponent
						v-if="eventsMeta"
						:page="eventsMeta.pagination.page"
						:page-size="eventsMeta.pagination.pageSize"
						:page-count="eventsMeta.pagination.pageCount"
						:total="eventsMeta.pagination.total"
					></PaginationComponent>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
	import wsExternal from "@/services/external";
	import PaginationComponent from "@/views/events/_Pagination.vue";

	export default {
		name: "TodosEventosShow",
		components: {
			PaginationComponent,
		},

		props: {
			cfgTitle: String,
		},

		data() {
			return {
				events: {},
				eventsMeta: {
					pagination: {
						page: 1,
					},
				},
				path: {
					base_url: process.env.VUE_APP_BASE_URL,
					endpoint: process.env.VUE_APP_ENDPOINT,
					storage_files: process.env.VUE_APP_STORAGE_FILES,
				},
			};
		},

		computed: {
			currentPage() {
				let page = this.$route.query.page;
				return page;
			},
		},

		mounted() {
			document.title = "Agenda | Instituto de los Mexicanos en el Exterior";
			this.loadEvents(10, this.currentPage ? this.currentPage : 1);
			//this.$route
		},

		methods: {
			loadEvents(pageSize, page) {
				wsExternal.getAllEvents(pageSize, page).then(
					(response) => {
						this.events = response.data.data;
						console.log("🚀 ~ loadEvents ~ this.events:", this.events);
						this.eventsMeta = response.data.meta;
					},
					(error) => {
						this.events =
							(error.response && error.response.data) ||
							error.message ||
							error.toString();
					}
				);
			},
		},
	};
</script>
